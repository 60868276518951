<template>
  <div class="settings w-100">
    <BaseTabs :routes="routes" />
    <div class="content content white-color-bg p-y-22 p-x-18 radius-14">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsPage',
  data () {
    return {
      routes: [
        {
          to: { name: 'account-details' },
          title: 'account.title'
        },

        {
          to: { name: 'password' },
          title: 'forms.password'
        },

        {
          to: { name: 'shipping-operations' },
          title: 'account.ShippingOperations'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  .content {
    box-shadow: 1px 3px 11px 3px rgba($black-color, 0.1);
  }
}
</style>
